<template>
  <div id="app">
    <el-row :gutter="20" class="flex-row">
      <el-col :xs="24" :lg="12" class="flex-col">
        <div class="main-l">
          <h4>VPS剩余价值计算器</h4>
          <el-form >
              <el-form-item label="续费价格">
                <el-input
                    v-model="renewPrice"
                />
              </el-form-item>
              
              <el-form-item label="币种-人民币" class="currency">
                <el-select
                    v-model="currency"
                    class="currency-select"
                    @change="changeCurrency"
                    >
                    <el-option  v-for="ele in currencyList"  :key="ele.value" :label="ele.label" :value="ele.value" />
                </el-select>
                <el-input-number class="currency-value" v-model="currencyValue" controls-position="right"  :min='1' :max="100"></el-input-number>
              </el-form-item>
                <el-form-item label="交易价格">
                    <el-input
                        placeholder="人民币"
                        v-model="sellPrice"
                    />
                </el-form-item>     

                <el-form-item label="到期日期">
                    <el-input
                        type="date"
                        v-model="dueDate"
                        label="到期日期"
                    />
                </el-form-item> 
            
                <el-form-item label="当前日期">
                    <el-input
                        type="date"
                        disabled
                        v-model="currentDate"
                    />
                </el-form-item>

                <el-form-item label="续费周期">
                  <el-select v-model="renewType">
                      <el-option  label="月付" value="1">
                          月付
                      </el-option>
                      <el-option label="季付" value="2">
                          季付
                      </el-option>
                      <el-option label="半年付" value="3">
                          半年付
                      </el-option>
                      <el-option label="年付"  value="4">
                          年付
                      </el-option>
                      <el-option label="二年付" value="5">
                          二年付
                      </el-option>
                      <el-option label="三年付" value="6">
                          三年付
                      </el-option>
                      <el-option label="四年付" value="7">
                          四年付
                      </el-option>
                      <el-option label="五年付" value="8">
                          五年付
                      </el-option>
                  </el-select>
              </el-form-item>
              </el-form>
              <el-button
                    gradient
                    @click="handleBtn"
                >
                计算
            </el-button> 
        </div>
      </el-col>
      <el-col :xs="24" :lg="12" class="flex-col">
        <div class="main-r">
            <h4>计算结果</h4>
            <template v-if="remainingDays">
              <h5>剩余时间：{{ remainingDays }}</h5>
              <h5>剩余价值：￥{{ residualValue }}</h5>
              <template v-if="minusValue>0">
                <el-alert
                  :title="`折价：￥${minusValue}`"
                  type="success"
                  :closable="false">
                </el-alert>
              </template>
              <template v-else-if="minusValue<0">
                <el-alert
                  :title="`溢价：￥${-minusValue}`"
                  type="error"
                  :closable="false">
                </el-alert>
              </template>
              <template v-else>
                <el-alert
                  title="剩余价值"
                  type="success"
                  :closable="false">
                </el-alert>
              </template>
            </template>
            <el-skeleton v-else />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import moment from 'moment';
import {GetRxchangeRate} from './api/mainReques'

export default {
  name: 'App',
   data(){
        return{
            renewPrice:'',//续费价格
            currency:'USD',//币种
            currencyList:[],
            rates:{},
            currencyValue:'',//币种汇率
            sellPrice:'',//交易价格
            renewType:'4',//付款周期,
            dueDate:'',//到期日期
            currentDate:moment(Date.now()).format('YYYY-MM-DD'),//当前时间
            remainingDays:'',
            residualValue:'',
            minusValue:0
        }
    },
    mounted(){
      document.title = 'VPS剩余价值计算器'
      let data = {
              base:'CNY',
            }
            console.log('---GetRxchangeRate')
            GetRxchangeRate(data).then(res=>{
              let currencyArr = [
                {
                  label:'CNY-人民币(￥)',
                  value:'CNY',
                  rate:1,
                },
                {
                  label:'USD-美元($)',
                  value:'USD',
                  rate:'',
                },
                {
                  label:'EUR - 欧元(€)',
                  value:'EUR',
                  rate:'',
                },
                {
                  label:'GBP - 英镑(£)',
                  value:'GBP',
                  rate:'',
                },
                {
                  label:'CAD - 加元(C$)',
                  value:'CAD',
                  rate:'',
                },
              //   {
              //     label:'HKD-港币(HK$)',
              //     value:'HKD',
              //     rate:'',
              //   },
              //   {
              //     label:'TWD-台币(NT$)',
              //     value:'TWD',
              //     rate:'',
              //   },
              //   {
              //     label:'RUB - 俄罗斯卢布(₽)',
              //     value:'RUB',
              //     rate:'',
              //   },
              //   {
              //     label:'JPY - 日元(Ұ)',
              //     value:'JPY',
              //     rate:'',
              //   }
              ]
              let rates = res.data?res.data.rates:{}
              this.rates = rates
              this.currencyValue = (1/rates['USD']).toFixed(4)
              currencyArr.map((ele,idx)=>{
                if(rates[currencyArr[idx].value]){
                  currencyArr[idx].rate = (1/rates[currencyArr[idx].value]).toFixed(4)
                }
              })
              this.currencyList = currencyArr
            })
    },
    methods:{
      changeCurrency(value){
        let currencyValue = this.rates[value]?(1/this.rates[value]).toFixed(4):1
        this.currencyValue =  currencyValue
        console.log(currencyValue)
      },
      handleBtn(){
        if(!this.renewPrice){

          this.$message.error('请输入续费价格')
          return
        }
        if(!this.sellPrice){

          this.$message.error('请输入交易价格')
          return
        }
        if(!this.dueDate){
          
          this.$message.error('请选择到期日期')
          return
        }

        const now = moment();
        const targetDate = moment(this.dueDate, "YYYY-MM-DD");
        const remainingSeconds = targetDate.diff(now, 'seconds');

        // 根据 renewType 计算每期的秒数
        let periodSeconds;
        switch (this.renewType) {
            case '1': // 月付
                periodSeconds = 30 * 24 * 60 * 60; // 30天的秒数
                break;
            case '2': // 季付
                periodSeconds = 90 * 24 * 60 * 60; // 90天的秒数
                break;
            case '3': // 半年付
                periodSeconds = 180 * 24 * 60 * 60; // 180天的秒数
                break;
            case '4': // 年付
                periodSeconds = 365 * 24 * 60 * 60; // 365天的秒数
                break;
            case '5': // 二年付
                periodSeconds = 730 * 24 * 60 * 60; // 730天的秒数
                break;
            case '6': // 三年付
                periodSeconds = 1095 * 24 * 60 * 60; // 1095天的秒数
                break;
            case '7': // 四年付
                periodSeconds = 1460 * 24 * 60 * 60; // 1095天的秒数
                break
            case '8': // 五年付
                periodSeconds = 1825 * 24 * 60 * 60; // 1095天的秒数
                break
            default:
                throw new Error("无效的付款周期类型");
        }

        // 计算每秒的费用
        const perSecondPrice = this.renewPrice*this.currencyValue / periodSeconds;

        // 计算剩余价值
        this.residualValue = (perSecondPrice * remainingSeconds).toFixed(2);

        // 将剩余秒数转换为 天, 小时, 分钟和秒
        const days = Math.floor(remainingSeconds / (24 * 60 * 60));
        const hours = Math.floor((remainingSeconds % (24 * 60 * 60)) / (60 * 60));
        const minutes = Math.floor((remainingSeconds % (60 * 60)) / 60);
        const seconds = remainingSeconds % 60;
        this.remainingDays = `剩余时间: ${days}天 ${hours}小时 ${minutes}分钟 ${seconds}秒`
        this.minusValue = (this.residualValue-this.sellPrice).toFixed(2)
      }
    }
}
</script>

<style lang="less" scoped>
#app{
  background: #f1f1f1;
}
.flex-row {
  display: flex;
  flex-wrap: wrap;
}

.flex-col {
  display: flex;
  flex-direction: column;
}
.main-l{
  background: #fff;
  margin: 15px;
  padding: 15px;
  border-radius: 8px;
  min-height: 36px;
  flex: 1; /* 使内容区域自适应高度 */
  /deep/.el-form-item{
    margin-bottom: 10px;
    .el-form-item__label{
      line-height: 30px;
    }
  }
  .currency{
    /deep/.el-form-item__content{
      display: flex;
      .currency-select{
        flex: 1;
        margin-right: 10px;
      }
      .currency-value{
        flex: 1;
      }
    }
  }
}
.main-r{
  background: #fff;
  margin: 15px;
  padding: 15px;
  border-radius: 8px;
  min-height: 36px;
  flex: 1; /* 使内容区域自适应高度 */

  
}
</style>
